<template>
  <div class="search-box">
    <el-form :inline="true" :model="queryForm" class="demo-form-inline">
      <el-form-item label="表名">
        <el-input
            v-model="queryForm.tableName"
            placeholder=""
            size="small"
            @keyup.enter="submit"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button icon="el-icon-search" type="primary" @click="submit" size="small">查询</el-button>
        <el-button icon="el-icon-download" @click="handleGenerate" size="small"
        >生成
        </el-button
        >
      </el-form-item>
    </el-form>
  </div>
  <div class="data-box">
    <el-table
        v-loading="loading"
        element-loading-text="加载中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        :data="tableData"
        style="width: 100%"
        @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection"/>
      <el-table-column prop="tableName" label="表名" show-overflow-tooltip="true"/>
      <el-table-column prop="engine" label="引擎"/>
      <el-table-column prop="tableComment" label="表说明" show-overflow-tooltip="true"/>
      <el-table-column prop="createTime" label="创建时间"/>
      <el-table-column prop="tableRows" label="当前表记录数"/>
      <el-table-column prop="tableCollation" label="表排序规则"/>
      <el-table-column label="操作" fixed="right" width="180">
        <template #default="scope">
          <el-button icon="el-icon-document" size="mini" @click="viewTableColumn(scope.row.tableName)">字段</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <div class="page-box">
    <el-pagination
        v-model:currentPage="curPage"
        :page-sizes="[15, 50, 100, 200]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background="true"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>

  <!--查看表字段弹窗-->
  <el-dialog
      v-model="dialogVisible"
      :title="dialogTitle"
      width="30%"
      @close="handleClose"
  >
    <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="80px"
        class="demo-ruleForm"
    >
      <el-form-item label="生成表" prop="tables" class="label-color">
        <el-input
            type="textarea"
            v-model="ruleForm.tables"
            placeholder="请输入生成表"
            clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="作者" prop="author" class="label-color">
        <el-input
            v-model="ruleForm.author"
            placeholder="请输入作者，如 Turbo"
            clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="表前缀" prop="prefix" class="label-color">
        <el-input
            v-model="ruleForm.prefix"
            placeholder="请输入表前缀，如 by"
            clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="基础包名" prop="packageName" class="label-color">
        <el-input
            v-model="ruleForm.packageName"
            placeholder="基础包名，默认com.ymxx.by"
            clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="模块名" prop="moduleName" class="label-color">
        <el-input
            v-model="ruleForm.moduleName"
            placeholder="请输入模块名，如 system"
            clearable
        ></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="startGenerate">生成</el-button>
      </span>
    </template>
  </el-dialog>
  <!--  查看字段弹窗-->
  <el-dialog
      v-model="columnDialog"
      :title="columnDialogTitle"
      width="60%"
  >
    <el-table
        v-loading="loading"
        element-loading-text="加载中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        :data="tableColumnDatas"
        height="450px"
        style="width: 100%"
    >
      <el-table-column label="序号" type="index"/>
      <el-table-column prop="columnName" label="字段名"/>
      <el-table-column prop="dataType" label="数据类型"/>
      <el-table-column prop="columnComment" label="字段说明" show-overflow-tooltip="true"/>
      <el-table-column prop="columnKey" label="是否为键"/>
      <el-table-column prop="extra" label="主键自增模式"/>
      <el-table-column prop="isNull" label="是否允许为空"/>
    </el-table>
  </el-dialog>
</template>

<script>
import generateCodeApi from "@/api/tools/generateCode";
import {download} from "@/utils/fileDownload";

export default {
  data() {
    return {
      queryForm: {
        tableName: "",
      },
      loading: false,
      total: 0,
      pageSize: 15,
      curPage: 1,
      tableData: [],
      ruleForm: {
        tables: "",
        author: "",
        prefix: "",
        packageName: "",
        moduleName: "",
      },
      rules: {
        tables: [
          {
            required: true,
            message: "请输入变量编码",
            trigger: ["blur","change"],
          },
        ],
        author: [
          {
            required: true,
            message: "请输入作者，如 Turbo",
            trigger: ["blur","change"],
          },
        ],
      },
      dialogTitle: "",
      dialogVisible: false,
      columnDialog: false,
      columnDialogTitle: '',
      tableColumnDatas:[],
      multipleSelection: []
    };
  },
  mounted() {
    this.queryData();
  },
  methods: {
    clearSelect(){
      this.queryData();
    },
    submit() {
      this.curPage = 1;
      this.queryData();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryData();
    },
    handleCurrentChange(val) {
      this.curPage = val;
      this.queryData();
    },
    queryData() {
      generateCodeApi.queryTables({
        tableName: this.queryForm.tableName,
        pageSize: this.pageSize,
        curPage: this.curPage,
      })
          .then((response) => {
            this.tableData = response.data.data.records;
            this.total = response.data.data.total;
          });
    },
    // 弹窗关闭事件
    handleClose() {
      // this.ruleForm = {};
    },
    //保存选中结果
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 开始生成
    startGenerate(){
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          // downLoadZip('/api/tools/generateCode/generate',this.ruleForm.tables);
          // 调用接口保存
          generateCodeApi.generate(this.ruleForm).then((response) => {
            this.dialogVisible=false;
            // 解析blob并下载文件
            download(response,"code.zip");
          });
        }
      });
    },
    handleGenerate() {
      // 获取选择的数据判断
      if(this.multipleSelection.length<1){
        this.$notify({
          title: '请先勾选数据',
          type: 'warning',
          offset:40,
          duration: 2000
        });
        return;
      }
      this.ruleForm.tables='';
      for(var j = 0; j < this.multipleSelection.length; j++) {
        var row=this.multipleSelection[j];
        this.ruleForm.tables+=row.tableName;
        if(this.multipleSelection.length>(j+1)){
          this.ruleForm.tables+=',';
        }
      }

      this.dialogTitle = "代码生成";
      this.dialogVisible = true;
    },
    // 查看表字段
    viewTableColumn(tableName){
      // 显示窗口
      this.columnDialogTitle=tableName+'表字段';
      this.columnDialog=true;
      // 调用接口查询
      generateCodeApi.queryTableColumn({
        tableName: tableName,
      })
          .then((response) => {
            this.tableColumnDatas = response.data.data;
          });
    }
  },
};
</script>

<style scoped>

.is-penultimate > .el-tree-node__content {
  color: red;
}
</style>