/**
 * 代码生成接口列表
 */
import request from '@/api/myaxios'; // 导入http中创建的axios实例

const sysVariables = {
    // 数据库表查询
    queryTables(data) {
        return request({
            url: '/api/tools/generateCode/queryTables',
            method: 'post',
            data: data
        });
    },
    // 表字段查询
    queryTableColumn(data) {
        return request({
            url: '/api/tools/generateCode/queryTableColumn',
            method: 'post',
            data: data
        });
    },
    // 生成代码
    generate(data) {
        return request({
            url: '/api/tools/generateCode/generate',
            method: 'post',
            data: data,
            responseType:'blob'
        });
    },
}

export default sysVariables;