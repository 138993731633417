/**
 * 解析blob响应内容并下载
 * @param {*} res blob响应内容
 * @param {String} 下载文件名
 */
export function download(res, fileName) {
    const aLink = document.createElement('a')
    aLink.style.display = 'none'
    aLink.href = URL.createObjectURL(res.data)
    aLink.setAttribute('download', fileName) // 设置下载文件名称
    document.body.appendChild(aLink)
    aLink.click()
    URL.revokeObjectURL(aLink.href);//清除引用
    document.body.removeChild(aLink);
}
